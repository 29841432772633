'use client'

import Image, { ImageProps } from 'next/image'
import { FC, useState } from 'react'
import isValidUrl from '@lib/utils/url/isValidUrl'
import ImagePlaceholder from './product-img-placeholder.svg'

// In case image src is invalid, we fallback to HTML img element to handle the error, since next/image can't handle invalid urls.
const Img: FC<ImageProps> = (props) => {
  const [imgSrc, setImageSrc] = useState(props.src)

  if (imgSrc && isValidUrl(imgSrc as string)) {
    return (
      <Image
        loading="lazy"
        {...props}
        alt={props.alt ?? ''}
        src={imgSrc}
        onError={() => {
          setImageSrc(ImagePlaceholder.src)
        }}
      />
    )
  }

  return (
    <img
      loading="lazy"
      placeholder={ImagePlaceholder.src}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null
        currentTarget.src = ImagePlaceholder.src
      }}
      style={{
        ...(props.width && {
          width: props.width,
        }),
        ...(props.height && {
          height: props.height,
        }),
      }}
      className={props.className}
      src={(imgSrc as string | undefined) ?? ''}
      alt={props.alt ?? ''}
    />
  )
}

export default Img
