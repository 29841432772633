'use client'

import { MOBILE_WIDTH, TABLET_WIDTH } from '@lib/constants/breakpoints'
import { useMediaQuery } from '@lib/hooks/useMediaQuery'

export const useLayout = () => {
  const mobileLayout = useMediaQuery(MOBILE_WIDTH - 1)
  const tabletLayout = useMediaQuery(TABLET_WIDTH - 1)

  return {
    isMobile: mobileLayout,
    isTablet: !mobileLayout && tabletLayout,
    isDesktop: !mobileLayout && !tabletLayout,
  }
}
