'use client'

import cn from 'clsx'
import { FC } from 'react'
import { Img } from '@components/ui'
import { useLayout } from '@lib/hooks/useLayout'
import { useClientTranslation } from '@app/i18n/client'
import WHITE_LOGO from './brandsales-w.png'
import DEFAULT_LOGO from './brandsales.png'

interface ILogoProps {
  isWhite?: boolean
  className?: string
  width?: number
}

const Logo: FC<ILogoProps> = ({ className, isWhite = false, width }) => {
  const { t } = useClientTranslation('common')

  const { isMobile } = useLayout()

  if (WHITE_LOGO.src && DEFAULT_LOGO.src) {
    return (
      <Img
        src={isWhite ? WHITE_LOGO.src : DEFAULT_LOGO.src}
        quality={85}
        width={width ?? isMobile ? 130 : 190}
        height={43}
        alt={t('Online footwear and clothing store')}
        title={t('Online footwear and clothing store') ?? ''}
        className={cn('object-contain', className)}
      />
    )
  }

  return null
}

export default Logo
