'use client'

import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import resourcesToBackend from 'i18next-resources-to-backend'
import { useParams } from 'next/navigation'
import { useEffect } from 'react'
import {
  initReactI18next,
  useTranslation,
  UseTranslationOptions,
} from 'react-i18next'
import { getOptions } from './settings'

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(
    resourcesToBackend(
      (language: string, namespace: string) =>
        import(`./locales/${language}/${namespace}.json`)
    )
  )
  .init({
    ...getOptions(),
    lng: undefined, // let detect the language on client side
    detection: {
      order: ['path', 'htmlTag', 'cookie', 'navigator'],
    },
  })

const runsOnServerSide = typeof window === 'undefined'

export function useClientTranslation(
  ns: string | string[],
  options?: UseTranslationOptions
) {
  const ret = useTranslation(ns, options)
  const params = useParams()
  const lang = params?.lang
  const { i18n } = ret

  const language = lang as string

  if (runsOnServerSide && i18n.resolvedLanguage !== language) {
    i18n.changeLanguage(language)
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (i18n.resolvedLanguage === language) return
      i18n.changeLanguage(language)
    }, [language, i18n])
  }

  return ret
}
