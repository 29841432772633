'use client'

import { useEffect } from 'react'
import { Logo } from '@components/ui'
import Link from '@components/ui/Link'
import handleSentry from '@lib/utils/handleSentry'
import { useClientTranslation } from '@app/i18n/client'

export default function Error({ error }: { error: Error }) {
  const { t } = useClientTranslation('common')

  useEffect(() => {
    handleSentry(error.message)
  }, [error])

  return (
    <main className="p-4 md:ml-[113px] md:max-w-[364px]">
      <Logo className="mb-8" />
      <h1 className="mb-12 text-4xl">{t('Internal server error header')}</h1>
      <p className="mb-8 font-medium">
        {t('Internal server error description')}
      </p>
      <Link className="primaryButton" href="/">
        {t('Back to homepage')}
      </Link>
    </main>
  )
}
