export const fallbackLng = 'lt'
// TODO: add 'en' when ready
export const languages = ['lt']
export const defaultNS = 'common'

export function getOptions(
  lng = fallbackLng,
  ns: string | string[] = defaultNS
) {
  return {
    supportedLngs: languages,
    fallbackLng,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns,
  }
}
