'use client'

import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import { useParams } from 'next/navigation'
import { FC, ReactNode } from 'react'
import urlJoin from 'url-join'
import { languages } from '@app/i18n/settings'
import { onStart } from '@app/router-events'

type LinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & NextLinkProps
interface ILinkProps extends LinkProps {
  children?: ReactNode
  noLocale?: boolean
}

const getUrl = (href: string, lang?: string, noLocale?: boolean) => {
  if (
    !lang ||
    noLocale ||
    href.includes('https') ||
    href.startsWith(`/${lang}/`)
  ) {
    return href
  }

  const isLocalePresent = languages.some(
    (locale) => href.startsWith(`/${locale}/`) && href !== `/${locale}`
  )

  if (isLocalePresent) {
    return href
  }

  return urlJoin('/', lang, href !== '/' ? href : '')
}

const Link: FC<ILinkProps> = ({
  href,
  children,
  onClick,
  noLocale,
  ...props
}) => {
  const label = typeof children === 'string' ? children : ''
  const params = useParams()
  const url = getUrl(href, params?.lang as string, noLocale)

  return (
    <NextLink
      href={url}
      aria-label={label}
      onClick={(e) => {
        const { pathname, search, hash } = window.location
        if (url !== urlJoin(pathname, search, hash)) {
          onStart()
        }

        if (onClick) {
          onClick(e)
        }
      }}
      {...props}
    >
      {children}
    </NextLink>
  )
}

export default Link
